interface Props {
  title: string;
}

export default function RenderSvg({ title }: Props) {
  if (title === "package") {
    return (
      <svg
        width={16}
        height={17}
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 6.52727L4.5 2.6499"
          stroke="#989EAF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 11.4577V5.48098C14.9997 5.21895 14.9277 4.96161 14.7912 4.73476C14.6547 4.50791 14.4585 4.31953 14.2222 4.18852L8.77778 1.20018C8.5413 1.06904 8.27306 1 8 1C7.72694 1 7.4587 1.06904 7.22222 1.20018L1.77778 4.18852C1.54154 4.31953 1.34532 4.50791 1.2088 4.73476C1.07229 4.96161 1.00028 5.21895 1 5.48098V11.4577C1.00028 11.7197 1.07229 11.977 1.2088 12.2039C1.34532 12.4307 1.54154 12.6191 1.77778 12.7501L7.22222 15.7384C7.4587 15.8696 7.72694 15.9386 8 15.9386C8.27306 15.9386 8.5413 15.8696 8.77778 15.7384L14.2222 12.7501C14.4585 12.6191 14.6547 12.4307 14.7912 12.2039C14.9277 11.977 14.9997 11.7197 15 11.4577Z"
          stroke="#989EAF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.20703 4.7041L7.99703 8.47688L14.787 4.7041"
          stroke="#989EAF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 15.9999V8.46924"
          stroke="#989EAF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (title === "security") {
    return (
      <svg
        width={14}
        height={17}
        viewBox="0 0 14 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 15.7692C7 15.7692 13 12.8154 13 8.38462V3.21538L7 1L1 3.21538V8.38462C1 12.8154 7 15.7692 7 15.7692Z"
          stroke="#989EAF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (title === "guarantee") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.5753 9.33212L9.33943 14.568C9.20378 14.7038 9.04271 14.8115 8.86541 14.885C8.68811 14.9585 8.49806 14.9963 8.30613 14.9963C8.11419 14.9963 7.92414 14.9585 7.74684 14.885C7.56954 14.8115 7.40847 14.7038 7.27283 14.568L1 8.30247V1H8.30247L14.5753 7.27283C14.8473 7.54647 15 7.91663 15 8.30247C15 8.68832 14.8473 9.05848 14.5753 9.33212V9.33212Z"
          stroke="#989EAF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.65137 4.65137H4.65867"
          stroke="#989EAF"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (title === "visacash") {
    return (
      <svg
        width={48}
        height={30}
        viewBox="0 0 48 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 4.09091C0 1.83156 1.84203 0 4.11429 0H43.8857C46.158 0 48 1.83156 48 4.09091V25.9091C48 28.1684 46.158 30 43.8857 30H4.11429C1.84203 30 0 28.1684 0 25.9091V4.09091Z"
          fill="url(#paint0_linear)"
        />
        <rect
          x="5.14453"
          y="18.75"
          width="9.25714"
          height="3.40909"
          rx={1}
          fill="url(#paint1_linear)"
        />
        <rect
          x="15.4258"
          y="18.75"
          width="20.2286"
          height="3.40909"
          rx={1}
          fill="url(#paint2_linear)"
        />
        <rect
          x="5.14453"
          y="23.1816"
          width="16.1143"
          height="3.40909"
          rx={1}
          fill="url(#paint3_linear)"
        />
        <rect
          x="5.14453"
          y="5.11328"
          width="9.6"
          height="6.13636"
          rx={3}
          fill="url(#paint4_linear)"
        />
        <path
          d="M34.7017 9.52269H33.5352L34.2648 5.19141H35.4312L34.7017 9.52269Z"
          fill="#FDFDFD"
        />
        <path
          d="M38.9289 5.29768C38.6988 5.21004 38.3338 5.11328 37.8826 5.11328C36.7306 5.11328 35.9195 5.70304 35.9145 6.54622C35.9049 7.16832 36.4953 7.51385 36.9368 7.72128C37.3881 7.93323 37.5415 8.07158 37.5415 8.26051C37.5369 8.55068 37.1768 8.68443 36.841 8.68443C36.3753 8.68443 36.1257 8.61547 35.7465 8.45404L35.5929 8.38483L35.4297 9.35711C35.7033 9.47678 36.2073 9.58297 36.7306 9.58763C37.9546 9.58763 38.7515 9.00699 38.7609 8.10845C38.7656 7.61539 38.4538 7.23759 37.7818 6.92886C37.3738 6.73068 37.1239 6.59705 37.1239 6.39428C37.1287 6.20994 37.3352 6.02113 37.7958 6.02113C38.175 6.01189 38.4536 6.0994 38.6647 6.18698L38.7702 6.23297L38.9289 5.29768Z"
          fill="#FDFDFD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.0142 5.19141H41.9165L42.8576 9.52263H41.7775C41.7775 9.52263 41.6718 9.02498 41.6383 8.87291H40.1407L39.8958 9.52263H38.6719L40.4046 5.55077C40.5246 5.26967 40.736 5.19141 41.0142 5.19141ZM40.9462 6.77655C40.9462 6.77655 40.5765 7.73958 40.4805 7.9884H41.4501C41.4021 7.77185 41.1812 6.73509 41.1812 6.73509L41.0997 6.36188C41.0653 6.45798 41.0157 6.59011 40.9822 6.67923C40.9595 6.73964 40.9442 6.78028 40.9462 6.77655Z"
          fill="#FDFDFD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.4762 5.19141H30.353C30.6074 5.20053 30.8138 5.27886 30.881 5.55549L31.2835 7.51957C31.2864 7.5284 31.2891 7.53723 31.2918 7.54604L31.4167 8.14505L32.5591 5.19153H33.7925L31.959 9.51822H30.7255L29.6862 5.75647C29.3267 5.55425 28.9162 5.39165 28.457 5.27892L28.4762 5.19141Z"
          fill="#FDFDFD"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="46.8"
            y1="1.18499e-06"
            x2="0.156951"
            y2="30.2421"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF4888" />
            <stop offset={1} stopColor="#F5B1B0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="14.7445"
            y1="18.75"
            x2="5.15696"
            y2="22.1937"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F8C4CD" />
            <stop offset={1} stopColor="#FBD0D5" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="36.4036"
            y1="18.75"
            x2="21.7669"
            y2="30.2381"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F8C4CD" />
            <stop offset={1} stopColor="#FBD0D5" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="21.8556"
            y1="23.1816"
            x2="8.30888"
            y2="31.6517"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F8C4CD" />
            <stop offset={1} stopColor="#FBD0D5" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="14.7445"
            y1="5.11328"
            x2="5.17408"
            y2="10.9571"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EFD9A9" />
            <stop offset={1} stopColor="#E2C68A" />
          </linearGradient>
        </defs>
      </svg>
    );
  } else if (title === "cash") {
    return (
      <svg
        width={49}
        height={30}
        viewBox="0 0 49 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.664062"
          y="0.648438"
          width="46.6327"
          height="28.5426"
          rx={6}
          fill="#A4DFBE"
        />
        <rect
          x="1.33203"
          y="1.29688"
          width="45.3003"
          height="27.2453"
          rx={6}
          fill="url(#paint0_linear)"
        />
        <rect
          x="8.66016"
          y="21.4062"
          width="10.6589"
          height="3.24348"
          rx={1}
          fill="url(#paint1_linear)"
        />
        <rect
          x="28.6445"
          y="21.4062"
          width="10.6589"
          height="3.24348"
          rx={1}
          fill="url(#paint2_linear)"
        />
        <ellipse
          rx="3.021"
          ry="2.61688"
          transform="matrix(0.835664 0.549241 -0.569683 0.821864 4.01422 26.1908)"
          fill="#A4DFBE"
        />
        <ellipse
          rx="3.021"
          ry="2.61688"
          transform="matrix(-0.835664 0.549241 0.569683 0.821864 4.01703 3.80998)"
          fill="#A4DFBE"
        />
        <ellipse
          rx="3.021"
          ry="2.61688"
          transform="matrix(0.835664 0.549241 -0.569683 0.821864 43.983 3.80998)"
          fill="#A4DFBE"
        />
        <ellipse
          rx="3.021"
          ry="2.61688"
          transform="matrix(-0.835664 0.549241 0.569683 0.821864 43.9858 26.1908)"
          fill="#A4DFBE"
        />
        <ellipse
          cx="39.3036"
          cy="14.9193"
          rx="3.33091"
          ry="3.24348"
          fill="#A4DFBE"
        />
        <ellipse
          cx="39.3054"
          cy="14.919"
          rx="2.66473"
          ry="2.59479"
          fill="#328357"
        />
        <ellipse
          cx="39.3068"
          cy="14.9202"
          rx="0.666181"
          ry="0.648696"
          fill="#A4DFBE"
        />
        <ellipse
          cx="23.9835"
          cy="12.0013"
          rx="8.32727"
          ry="8.10871"
          fill="#A4DFBE"
        />
        <ellipse
          cx="23.9832"
          cy="12.0008"
          rx="6.9949"
          ry="6.81131"
          fill="#328357"
        />
        <path
          d="M24.3153 8.43359L20.9844 12.3258H23.9822L23.6491 14.9206L26.98 11.0284H23.9822L24.3153 8.43359Z"
          fill="#A4DFBE"
          stroke="#A4DFBE"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <ellipse
          cx="8.65903"
          cy="14.9193"
          rx="3.33091"
          ry="3.24348"
          fill="#A4DFBE"
        />
        <ellipse
          cx="8.66082"
          cy="14.919"
          rx="2.66473"
          ry="2.59479"
          fill="#328357"
        />
        <ellipse
          cx="8.65837"
          cy="14.9202"
          rx="0.666181"
          ry="0.648696"
          fill="#A4DFBE"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="45.4999"
            y1="1.29688"
            x2="2.497"
            y2="30.2711"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#40AF7E" />
            <stop offset={1} stopColor="#52CA7F" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="19.7138"
            y1="21.4062"
            x2="9.23103"
            y2="25.963"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A6E1BE" />
            <stop offset={1} stopColor="#A2DBBE" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="39.6982"
            y1="21.4062"
            x2="29.2154"
            y2="25.963"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A6E1BE" />
            <stop offset={1} stopColor="#A2DBBE" />
          </linearGradient>
        </defs>
      </svg>
    );
  } else if (title === "success") {
    return (
      <svg
        width={143}
        height={157}
        viewBox="0 0 143 157"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d)">
          <path
            d="M30.0896 25.9845C29.6555 26.579 28.7913 26.753 28.1953 26.3667L26.6782 25.3808L25.214 29.2856L21.5862 27.0148L21.4701 27.2781C21.2196 27.8525 20.7132 28.3122 20.0869 28.5401L18.1728 29.2367L17.3456 26.9638L19.1356 26.3124L20.7106 23.3807L23.8538 25.4271L25.4256 21.4832L29.8261 24.3608C30.3761 24.7189 30.4927 25.4314 30.0896 25.9845Z"
            fill="url(#paint0_linear)"
          />
          <path
            d="M25.1886 29.2108L26.6314 25.3633L26.6533 25.305L26.7055 25.3389L28.2225 26.3247L25.1886 29.2108ZM25.1886 29.2108L21.6127 26.9724L21.5637 26.9417L21.5404 26.9946L21.4243 27.2579L21.4242 27.2581C21.1796 27.819 20.6843 28.2695 20.0698 28.4931L18.2027 29.1726M25.1886 29.2108L18.2027 29.1726M18.2027 29.1726L17.4097 26.9937L19.1527 26.3594L19.1706 26.3529L19.1797 26.3361L20.7289 23.4523L23.8265 25.469L23.8777 25.5023L23.9003 25.4456L25.4494 21.5585L29.7987 24.4026L29.7988 24.4027C30.3231 24.744 30.4366 25.4234 30.0492 25.9551C29.6298 26.5293 28.795 26.6958 28.2226 26.3248L18.2027 29.1726Z"
            stroke="#9F9E9E"
            strokeWidth="0.1"
          />
        </g>
        <g filter="url(#filter1_d)">
          <path
            d="M99.571 14.5261C99.2025 14.9759 98.5134 15.0753 98.0603 14.7445L96.9069 13.9006L95.5811 16.9161L92.8194 14.9662L92.7163 15.1688C92.4936 15.6109 92.0741 15.9513 91.5702 16.1036L90.0301 16.5692L89.4772 14.7404L90.9175 14.305L92.288 12.0618L94.6774 13.8134L96.0897 10.7717L99.4342 13.2339C99.8523 13.5404 99.9131 14.1075 99.571 14.5261Z"
            fill="url(#paint1_linear)"
          />
          <path
            d="M95.56 16.8399L96.8612 13.8804L96.8862 13.8234L96.9365 13.8602L98.0898 14.7041L95.56 16.8399ZM95.56 16.8399L92.8482 14.9254L92.8011 14.8921L92.7748 14.9436L92.6717 15.1461L92.6716 15.1463C92.4556 15.5752 92.0476 15.907 91.5557 16.0557L90.0635 16.5068M95.56 16.8399L90.0635 16.5068M90.0635 16.5068L89.5396 14.7738L90.9319 14.3529L90.9502 14.3473L90.9601 14.3311L92.3023 12.1343L94.6478 13.8537L94.697 13.8898L94.7227 13.8344L96.1093 10.8482L99.4046 13.2742L99.4046 13.2742C99.798 13.5626 99.8575 14.0964 99.5323 14.4944C99.1797 14.9249 98.5202 15.0184 98.0898 14.7042L90.0635 16.5068Z"
            stroke="#9F9E9E"
            strokeWidth="0.1"
          />
        </g>
        <g filter="url(#filter2_d)">
          <path
            d="M43.6797 142.561C42.9183 142.699 42.1346 142.204 41.9614 141.478L41.5221 139.628L37.5763 141.537L36.463 137.178L36.1837 137.294C35.5765 137.55 34.858 137.532 34.2162 137.251L32.2547 136.393L33.2737 134.064L35.1079 134.867L38.4323 133.778L39.3397 137.615L43.3956 135.754L44.6568 141.135C44.8155 141.806 44.3875 142.432 43.6797 142.561Z"
            fill="url(#paint2_linear)"
          />
          <path
            d="M37.6096 141.465L41.5003 139.583L41.5564 139.556L41.5707 139.616L42.01 141.466L37.6096 141.465ZM37.6096 141.465L36.5115 137.166L36.4972 137.11L36.4438 137.132L36.1645 137.248L36.1643 137.248C35.5708 137.498 34.8665 137.481 34.2362 137.206L32.3205 136.368M37.6096 141.465L32.3205 136.368M32.3205 136.368L33.2994 134.13L35.0879 134.913L35.1054 134.92L35.1235 134.914L38.3961 133.843L39.291 137.626L39.3051 137.686L39.3605 137.66L43.3609 135.825L44.6081 141.146L44.6081 141.146C44.7598 141.788 44.3524 142.388 43.6708 142.512C42.934 142.645 42.1768 142.165 42.01 141.467L32.3205 136.368Z"
            stroke="#9F9E9E"
            strokeWidth="0.1"
          />
        </g>
        <g filter="url(#filter3_d)">
          <path
            d="M129.638 63.1985C128.756 63.2714 127.918 62.6224 127.8 61.7766L127.503 59.6214L122.8 61.3618L122.011 56.2763L121.681 56.3779C120.961 56.602 120.145 56.5032 119.445 56.113L117.305 54.9204L118.722 52.3801L120.722 53.4953L124.627 52.6212L125.239 57.0894L130.062 55.4159L130.907 61.6814C131.014 62.4632 130.458 63.1298 129.638 63.1985Z"
            fill="url(#paint3_linear)"
          />
          <path
            d="M122.84 61.2937L127.486 59.5745L127.544 59.5529L127.553 59.6145L127.85 61.7697L122.84 61.2937ZM122.84 61.2937L122.061 56.2687L122.052 56.2116L121.997 56.2285L121.666 56.3301L121.666 56.3301C120.96 56.5499 120.158 56.4534 119.469 56.0693L117.373 54.901M122.84 61.2937L117.373 54.901M117.373 54.901L118.741 52.4481L120.698 53.539L120.714 53.5483L120.733 53.5441L124.585 52.6819L125.19 57.0961L125.198 57.1566L125.256 57.1366L130.021 55.4832L130.858 61.688L130.858 61.6881C130.961 62.4397 130.427 63.0822 129.634 63.1487C128.777 63.2195 127.963 62.5878 127.85 61.7697L117.373 54.901Z"
            stroke="#9F9E9E"
            strokeWidth="0.1"
          />
        </g>
        <path
          d="M4.3694 91.7649C-8.33438 62.8285 9.66264 46.7724 20.2491 42.3613C24.392 65.8978 31.7419 107.362 33.7204 117.542C33.932 118.088 34.0756 118.613 34.1438 119.113C34.3379 120.536 34.1644 119.827 33.7204 117.542C30.6475 109.617 13.2421 97.2674 4.3694 91.7649Z"
          fill="#FFC508"
        />
        <path
          d="M88.1798 26.0389L42.7461 33.5377C45.6868 30.3029 54.6559 22.9512 67.0068 19.4223C79.3577 15.8935 86.2683 22.363 88.1798 26.0389Z"
          fill="#FFC508"
        />
        <path
          d="M130.084 119.332C136.436 110.686 123.468 92.9393 116.189 85.1465L118.836 135.212C119.939 133.521 123.732 127.978 130.084 119.332Z"
          fill="#FFC508"
        />
        <path
          d="M113.764 138.742L59.7285 146.903C64.5807 148.888 76.711 152.372 86.4153 150.431C96.1196 148.491 108.691 141.83 113.764 138.742Z"
          fill="#FFC508"
        />
        <path
          d="M107.147 22.291L42.3047 34.2008L105.382 24.7171L123.688 129.038L107.147 22.291Z"
          fill="#1B1C1F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75.9272 66.2031C71.4847 65.3599 66.8793 65.9655 62.7995 67.9307C58.72 69.8958 55.3894 73.1127 53.3008 77.0967C51.2124 81.0802 50.4769 85.6177 51.1989 90.033C51.921 94.4483 54.0627 98.5106 57.3084 101.614C60.5545 104.717 64.7329 106.697 69.2229 107.25C73.7132 107.804 78.2702 106.902 82.2126 104.677C86.1547 102.453 89.2668 99.0288 91.0892 94.9202C92.9111 90.8126 93.3472 86.2397 92.3369 81.8823C92.3368 81.8817 92.3367 81.881 92.3365 81.8804L92.3369 81.8823M92.3365 81.8804L91.835 79.724C91.4212 77.9443 92.5284 76.1662 94.308 75.7523C96.0876 75.3385 97.8658 76.4457 98.2796 78.2253L98.782 80.3855C100.118 86.1468 99.5399 92.1865 97.1375 97.6029C94.7353 103.019 90.639 107.52 85.464 110.44C80.2892 113.359 74.3089 114.544 68.4131 113.817C62.517 113.09 57.017 110.489 52.736 106.396C48.4545 102.303 45.6236 96.9378 44.6691 91.1008C43.7146 85.264 44.6886 79.274 47.4407 74.0245C50.1925 68.7755 54.574 64.5487 59.9281 61.9697C65.282 59.3907 71.3262 58.5951 77.161 59.7026C78.9561 60.0433 80.1351 61.7747 79.7943 63.5698C79.4536 65.3648 77.7222 66.5438 75.9272 66.2031"
          fill="#21222B"
        />
        <path
          d="M91.088 58.1016L79.3987 75.3046L76.9727 90.7432L96.6018 61.6304L91.088 58.1016Z"
          fill="#21222B"
          stroke="#21222B"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M95.7667 61.3707L75.7471 92.9287C74.7803 94.4527 72.7705 94.9206 71.23 93.9803L59.6877 86.9353L63.1348 81.2877L71.9017 86.6387L90.1796 57.8264L95.7667 61.3707Z"
          fill="#FFC508"
        />
        <defs>
          <filter
            id="filter0_d"
            x="5.3457"
            y="11.4824"
            width="36.9838"
            height="31.8025"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy={2} />
            <feGaussianBlur stdDeviation={6} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d"
            x="77.4766"
            y="0.771484"
            width="34.3152"
            height="30.1444"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy={2} />
            <feGaussianBlur stdDeviation={6} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d"
            x="20.2539"
            y="123.779"
            width="36.4351"
            height="32.8056"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy={2} />
            <feGaussianBlur stdDeviation={6} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d"
            x="105.305"
            y="42.3809"
            width="37.615"
            height="34.824"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy={2} />
            <feGaussianBlur stdDeviation={6} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="22.5641"
            y1="22.5245"
            x2="25.0468"
            y2="29.3465"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFEE4D" />
            <stop offset={1} stopColor="#EAD828" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="93.7873"
            y1="11.4677"
            x2="95.4466"
            y2="16.9567"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFEE4D" />
            <stop offset={1} stopColor="#EAD828" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="40.4634"
            y1="134.471"
            x2="37.405"
            y2="141.462"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFEE4D" />
            <stop offset={1} stopColor="#EAD828" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="126.864"
            y1="53.6331"
            x2="122.614"
            y2="61.2577"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFEE4D" />
            <stop offset={1} stopColor="#EAD828" />
          </linearGradient>
        </defs>
      </svg>
    );
  } else {
    return <></>;
  }
}
